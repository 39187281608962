import { tns } from "tiny-slider/src/tiny-slider";
const $window = $(window);

const $sliderArticles = $('.js-slider-articles');
const $sliderActions = $('.js-slider-articles .js-slider-action');
const allSlides = $sliderArticles.find('.slider__slide');

const createSlider = ($slider) => {
	if (! $slider.length) {
		return;
	}

	let slider = tns({
		container: $slider.find('.slider__slides')[0],
		items: 2,
		speed: 1500,
		gutter: 135,
		slideBy: 1,
		nav: false,
		edgePadding: 20,

		prevButton:$slider.find('.slider__prev')[0],
		nextButton: $slider.find('.slider__next')[0],
		responsive:  {
			320: {
				gutter: 30,
				items: 1,
				controls: false,
			},

			768: {
				gutter: 65,
				controls: true,
			},

			1024: {
				gutter: 90,
				items: 2,
			},

			1140: {
				gutter: 135,
			},
		},
	})

	return slider;
}

let slider = createSlider($sliderArticles);

const allCloneSlides = Array.from($sliderArticles.find('.tns-slide-cloned'));

/**
 *	Find the target slide for animation and toggles animation class on it.
 *	And remove it from his sibligs.
 *
 * @param  {[jQuery Object]}
 *
 * @return {void}
 */

const animateSlide = ($slider) => {
	if (! $slider.length) {
		return;
	}

	const activeSlides = $sliderArticles.find('.tns-slide-active');
	let $targetSlide = $(activeSlides[2]);

	if ($window.outerWidth() < 1024) {
		$targetSlide = $(activeSlides[1]);
	}

	$targetSlide.toggleClass('is-animated')
		.siblings('.tns-slide-active')
			.toggleClass('is-animated', false);

	if ( allCloneSlides.indexOf($targetSlide[0]) === allCloneSlides.length - 2) {
		$(allSlides[2]).toggleClass('is-animated')
	}
}

$window.on('load', function (evt) {
	animateSlide($sliderArticles);
})

if (slider) {
	slider.events.on('indexChanged', function() {
		animateSlide($sliderArticles);
	});
}

