import { tns } from "tiny-slider/src/tiny-slider";

const $sliderTopics = $('.slider-topics');

const createSlider = ($slider) => {
	if (! $slider.length) {
		return;
	}

	let slider = tns({
		container: $sliderTopics.find('.slider__slides')[0],
		items: 1,
		speed: 1500,
		slideBy: 1,
		nav: false,
		prevButton:$sliderTopics.find('.slider__prev')[0],
		nextButton: $sliderTopics.find('.slider__next')[0],
		mode: 'gallery',
	})
};

createSlider($sliderTopics);
