import '../vendor/marquee.min';

const handleTicker = () => {
	$('.js-marquee-holder').marquee({
		duration: 15000,
		delayBeforeStart: 0,
		direction: 'left',
		duplicated: true,
		css3easing: 'linear',
		startVisible: true,
	});
};

handleTicker();
