$('.js-accordion .accordion__section').not('.is-current').find('.accordion__body').hide()

$('.js-accordion').on('click', '.accordion__head', function(evt) {
	evt.preventDefault();

	$(this)
		.next()
			.stop()
			.slideToggle()
		.parent()
			.toggleClass('is-current')
		.siblings()
			.removeClass('is-current')
				.find('.accordion__body')
					.slideUp()
});

$('.scroll-accordion').on('click', function() {
	const scrollTo = $(this).data('scroll-to');
	$("#"+scrollTo).find('.accordion__head').trigger("click");
});
