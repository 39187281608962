const $arrows = $('.js-arrow-top, .js-arrow-bottom');

const scrollTo = ($arrow) => {
	if ($arrow.hasClass('js-arrow-top')) {
		$("html, window").animate({scrollTop: 0}, 700);
		return;
	}

	const $targetElement = $($arrow.attr('href'));
	const targetCoordinates = $targetElement.offset().top;

	$('html, window').animate({scrollTop: targetCoordinates}, 700)
}

$arrows.on('click', function(evt) {
	evt.preventDefault();

	scrollTo($(this));
})
