import { tns } from "tiny-slider/src/tiny-slider";
const $window = $(window);

const $mobileSlider = $('.js-mobile-slider');
const allSlides = $mobileSlider.find('.articles__item');
let isInitialized = false;

const createSlider = ($slider) => {
	if (! $slider.length) {
		return;
	}

	let slider = tns({
		container: $slider.find('.articles__items')[0],
		items: 1,
		speed: 1500,
		gutter: 135,
		slideBy: 1,
		nav: false,
		edgePadding: 20,
		controls: false,
		mouseDrag: true,

		responsive:  {
			320: {
				gutter: 30,
			},

			768: {
				disable: true,
			},
		},
	})

	return slider;
}


const allCloneSlides = Array.from($mobileSlider.find('.tns-slide-cloned'));

/**
 *	Find the target slide for animation and toggles animation class on it.
 *	And remove it from his sibligs.
 *
 * @param  {[jQuery Object]}
 *
 * @return {void}
 */

const animateSlide = ($slider) => {
	if (! $slider.length) {
		return;
	}

	const activeSlides = $slider.find('.tns-slide-active');
	let $targetSlide = $(activeSlides[1]);

	$targetSlide.toggleClass('is-animated')
		.siblings('.tns-slide-active')
			.toggleClass('is-animated', false);

	if ( allCloneSlides.indexOf($targetSlide[0]) === allCloneSlides.length - 2) {
		$(allSlides[2]).toggleClass('is-animated')
	}
}

$window.on('load resize', function (evt) {
	if ($window.outerWidth() > 768) {
		return;
	}

	if (isInitialized) {
		return;
	}

	let slider = createSlider($mobileSlider);

	if (slider) {
		isInitialized = true;
	}

	if (! slider) {
		return
	}

	animateSlide($mobileSlider);

	slider.events.on('indexChanged', function() {
		animateSlide($mobileSlider);
	});
})


